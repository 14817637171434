import { Menu, SUBMENU_VALIDATIONS } from './models/menu';

export const SITE_NAME = 'Rumble Kong League';
export const API_HOST = '';
export const GA_ID = 'G-4LG6NS83ET';
export const GRAPHQL_API_HOST =
    process.env.NEXT_PUBLIC_GRAPHQL_API_HOST || 'https://api.dev.rl.games/graphql';

/**
 *  ROUTES & MENUS
 **/
export const ROUTES = {
    DEFAULT: '/',
    ABOUT_US: '/about',
    CLUBS: '/clubs',
    STAKING: '/staking',
    MY_KONGS: '/my-kongs',
    MY_CLUBS: '/my-clubs',
    MY_REQUESTS: '/my-requests',
    WALLET: '/wallet',
    MARKETPLACE: '/marketplace',
    AUCTION: '/auction',
    AUCTION_CURRENT: '/auction/current',
    AUCTION_PREVIOUS: '/auction/previous',
    OVERVIEW: '/overview',
    DATA_PROTECTION: '/data-protection',
    TERMS_AND_CONDITIONS: '/terms-and-conditions',
};

export const MENU: Menu[] = [
    {
        title: 'Clubs',
        path: ROUTES.CLUBS,
    },
    // [TBD] This feature is available when Clubs is released
    // {
    //     title: 'Staking',
    //     path: ROUTES.STAKING,
    // },
    // {
    //     title: 'Market',
    //     path: ROUTES.MARKETPLACE,
    // },
    {
        title: 'Auction',
        path: ROUTES.AUCTION_CURRENT,
        dropdown: true,
    },
];

export const MENU_MOBILE: Menu[] = [
    {
        title: 'Clubs',
        path: ROUTES.CLUBS,
    },
    {
        title: 'Auction',
        path: ROUTES.AUCTION_CURRENT,
        dropdown: true,
    },
    // {
    //     title: 'Previous Auction',
    //     path: ROUTES.AUCTION_PREVIOUS,
    //     dropdown: true,
    // },
];

export const ACCOUNT_MENU: Menu[] = [
    // [TBD] This feature is available when Clubs is released
    // {
    //     title: 'My Wallet',
    //     path: ROUTES.WALLET,
    // },
    {
        title: 'My Kongs',
        path: ROUTES.MY_KONGS,
    },
    {
        title: 'My Clubs',
        path: ROUTES.MY_CLUBS,
        submenuValidate: SUBMENU_VALIDATIONS.MY_CLUBS,
        submenu: true,
    },
    {
        title: 'My Requests',
        path: ROUTES.MY_REQUESTS,
    },
];

export const AUCTION_MENU: Menu[] = [
    { title: 'Current', path: ROUTES.AUCTION_CURRENT },
    // { title: 'Previous', path: ROUTES.AUCTION_PREVIOUS },
];

export const FOOTER_MENU: Menu[] = [
    {
        title: 'Blog',
        href: 'https://rumblekongleague.substack.com/',
    },
    {
        title: 'Kongpaper',
        href: '/assets/kongpaper.pdf',
    },
    {
        title: 'Join Us',
        href: 'https://angel.co/company/rumble-league-studios/jobs',
    },
    {
        title: 'Press Kit',
        href: 'https://drive.google.com/drive/u/1/folders/1968eNO2IYgNWDp-yxH_3BFyJCJ2xuJ3S',
    },
    {
        title: 'Terms & Conditions',
        path: ROUTES.TERMS_AND_CONDITIONS,
    },
];
