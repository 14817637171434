import React from 'react';
import { DEFAULT_ICON_COLOR, IconProps, styledIcon } from './utils';

const RKLTwitterIcon: React.FC<IconProps> = (props: IconProps) => {
    return (
        <svg
            width="27"
            height="27"
            viewBox="0 0 27 27"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            style={styledIcon(props)}
        >
            <g clipPath="url(#clip0_142_514)">
                <path
                    d="M8.44648 24.388C18.5757 24.388 24.1176 15.994 24.1176 8.71691C24.1176 8.48094 24.1123 8.23971 24.1019 8.00373C25.1799 7.2241 26.1103 6.25842 26.8492 5.15206C25.8452 5.59876 24.7792 5.8905 23.6876 6.01731C24.8369 5.32838 25.6975 4.24609 26.1098 2.97109C25.0285 3.61188 23.8461 4.0639 22.6131 4.30778C21.7823 3.42505 20.6839 2.84059 19.4877 2.64474C18.2914 2.44889 17.064 2.65257 15.9951 3.22429C14.9262 3.796 14.0755 4.70391 13.5743 5.80764C13.0732 6.91137 12.9496 8.14945 13.2227 9.33046C11.0333 9.22059 8.89148 8.65185 6.93602 7.66111C4.98055 6.67037 3.25512 5.27975 1.87158 3.57939C1.16839 4.79177 0.953209 6.22641 1.26978 7.59174C1.58634 8.95708 2.4109 10.1506 3.57587 10.9299C2.70128 10.9021 1.84585 10.6666 1.08026 10.2429V10.3111C1.07948 11.5834 1.51933 12.8167 2.32504 13.8014C3.13075 14.786 4.2526 15.4613 5.49989 15.7124C4.68972 15.934 3.83941 15.9663 3.01476 15.8068C3.36672 16.901 4.05151 17.858 4.97355 18.5442C5.8956 19.2305 7.00889 19.6118 8.15806 19.6349C6.20711 21.1674 3.79712 21.9986 1.31624 21.9947C0.876276 21.994 0.436746 21.967 0 21.9139C2.52031 23.5308 5.4521 24.3896 8.44648 24.388Z"
                    fill={props.color || "#707070"}
                />
            </g>
            <defs>
                <clipPath id="clip0_142_514">
                    <rect
                        width="26.8492"
                        height="26.8492"
                        fill={props.color || 'white'}
                        transform="translate(0 0.0556641)"
                    />
                </clipPath>
            </defs>
        </svg>
    );
};

export default RKLTwitterIcon;
