import React from 'react';
import buttonStyle from './button.module.css';
import style from '../styles/styles.module.css';

export interface ButtonProps {
    size: 'normal' | 'small' | 'medium' | 'x-small';
    color: 'white' | 'none' | 'rose' | 'border' | 'black';
    className?: string;
    href?: string;
    target?: '_blank';
    onClick?: () => void;
    disabled?: boolean;
    onRef?: React.Ref<HTMLAnchorElement>;
}

const Button: React.FC<ButtonProps> = (props) => {
    const { size, color, className, onRef, children, ...rest } = props;

    let btnColor = '';
    let sizeClass = '';
    let additionalClasses = '';

    if (size === 'normal') {
        sizeClass = buttonStyle.sizeNormal;
    } else if (size === 'small') {
        sizeClass = buttonStyle.sizeSmall;
    } else if (size === 'x-small') {
        sizeClass = buttonStyle.sizeXSmall;
    } else {
        sizeClass = buttonStyle.sizeMedium;
    }

    if (color === 'white') {
        btnColor = `${style.bgColorWhite} ${style.fontColorBlack}`;
    } else if (color === 'none') {
        btnColor = `${style.bgColorTransparent} ${style.fontColorWhite}`;
    } else if (color === 'rose') {
        btnColor = `${style.bgColorRose} ${style.fontColorBlack}`;
    } else if (color === 'border') {
        btnColor = `${style.bgColorTransparent} ${style.borderColorBlack} ${style.fontColorBlack}`;
    } else {
        btnColor = `${style.bgColorBlack} ${style.fontColorWhite}`;
    }

    if (props.disabled) {
        additionalClasses = `${buttonStyle.disabled}`;
    }

    return (
        <a
            className={`${buttonStyle.button} ${sizeClass} ${style.fontSizeMedium} ${
                className || ''
            } ${props.disabled ? additionalClasses : btnColor}`}
            ref={onRef || undefined}
            {...rest}
        >
            {children}
        </a>
    );
};

export default Button;
