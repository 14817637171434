import React, { useRef } from 'react';
import modalStyle from './rkl-modal-dialog.module.css';
import RKLTimesFilledIcon from '../../icons/rkl-times-filled';
import useOutsideClick from '../../hooks/useOutsideClick';
import { xmSmallViewportWidth } from '../../../../media-queries';
import useMediaQuery from '../../../hooks/use-media-query';

export interface RKLModalDialogProps {
    isOpen: boolean;
    hasOnClose: boolean;
    onClose(): void;
    width?: string;
    height?: string;
    borderColor?: string;
    padding?: string;
    topView?: boolean;
}

export enum DEFAULT_MODAL_SIZES {
    WIDTH = '562px',
    HEIGHT = '332px',
}

const RKLModalDialog: React.FC<RKLModalDialogProps> = ({
    isOpen,
    children,
    onClose,
    hasOnClose,
    width,
    height,
    padding,
    borderColor,
    topView,
}) => {
    const isFullLayout = useMediaQuery(xmSmallViewportWidth);
    const insideRef = useRef(null);
    useOutsideClick(insideRef, onClose);
    if (!isOpen) {
        return null;
    }

    return (
        <div
            className={`${modalStyle.container} ${
                topView
                    ? isFullLayout
                        ? modalStyle.containerTopView
                        : modalStyle.containerTopViewMobile
                    : ''
            }`}
        >
            <div
                ref={insideRef}
                className={`${modalStyle.dialog} flex-center`}
                style={{
                    width: isFullLayout ? width || DEFAULT_MODAL_SIZES.WIDTH : '350px',
                    height: height || DEFAULT_MODAL_SIZES.HEIGHT,
                    borderColor,
                }}
            >
                <div className={modalStyle.navigation}>
                    {hasOnClose && (
                        <div className={modalStyle.closeModalButton} onClick={onClose}>
                            <RKLTimesFilledIcon />
                        </div>
                    )}
                </div>
                <div className={modalStyle.inner} style={{ padding }}>
                    {children}
                </div>
            </div>
        </div>
    );
};

export default RKLModalDialog;
