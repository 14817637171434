import { createContext, useState, Dispatch, SetStateAction, useEffect, useCallback } from 'react';
import { ethers } from 'ethers';
import router from 'next/router';
import { ROUTES } from '../../app.config';
import WalletConnectProvider from '@walletconnect/web3-provider';
import Web3Modal from 'web3modal';

type BlockchainContextT = {
    provider: ethers.providers.Web3Provider;
    defaultProvider: ethers.providers.AlchemyWebSocketProvider;
    setProvider: Dispatch<SetStateAction<ethers.providers.Web3Provider>>;
    setDefaultProvider: Dispatch<SetStateAction<ethers.providers.AlchemyWebSocketProvider>>;
    selectedAddress: string;
    setSelectedAddress: Dispatch<SetStateAction<string>>;
    onWalletDisconnect: () => Promise<void>;
    onWalletConnect: () => Promise<void>;
};

const providerOptions = {
    walletconnect: {
        package: WalletConnectProvider,
        options: {
            infuraId: process.env.NEXT_PUBLIC_INFURA_ID,
            pollingInterval: 15000
        },
    },
};

const storeWalletConnectStatus = (enabled: boolean) => {
    try {
        localStorage.setItem('walletconnect', String(enabled));
    } catch {
        // TBD
    }
};

export const TARGET_CHAIN_IDS = {
    ETHEREUM: 1,
    KOVAN: 42,
};

const BlockchainContext = createContext<BlockchainContextT>({
    provider: null,
    defaultProvider: null,
    setProvider: null,
    setDefaultProvider: null,
    selectedAddress: undefined,
    setSelectedAddress: null,
    onWalletDisconnect: null,
    onWalletConnect: null,
});

const BlockchainProvider: React.FC = ({ children }) => {
    const [isChecked, setIsChecked] = useState<boolean>(false);
    const [web3Modal, setWeb3Modal] = useState<Web3Modal>(null);
    const [provider, setProvider] = useState<BlockchainContextT['provider']>(null);
    const [defaultProvider, setDefaultProvider] = useState<BlockchainContextT['defaultProvider']>(null);
    const [selectedAddress, setSelectedAddress] = useState<string>(undefined);

    const onWalletDisconnect = useCallback(async () => {
        if (!web3Modal) return;

        try {
            await web3Modal.clearCachedProvider();
            
            storeWalletConnectStatus(false);

            setProvider(undefined);
            setSelectedAddress(undefined);

            router.push(ROUTES.DEFAULT);
        } catch (e) {
            console.warn('onWalletDisconnect: ', e);
        }
    }, [router, web3Modal, setProvider, setSelectedAddress]);

    const onWalletConnect = useCallback(async () => {
        if (!web3Modal) return;
        try {
            const instance = await web3Modal.connect();
            // todo: if chainId is not '0x1' then show notification
            // if chainId is fine, set the provider
            const provider_ = new ethers.providers.Web3Provider(instance);
            const address = await provider_.getSigner().getAddress();
            storeWalletConnectStatus(true);
            setProvider(provider_);
            setSelectedAddress(address);
        } catch (e) {
            console.warn('onWalletConnect: ', e);
        }
    }, [web3Modal, setProvider, setSelectedAddress]);

    useEffect(() => {
        if (typeof window !== 'undefined' && window?.ethereum) {
            window?.ethereum &&
                window?.ethereum.on('chainChanged', () => {
                    setTimeout(() => {
                        window.location.reload();
                    }, 1);
                });
        }
    });

    useEffect(() => {
        const checkProvider = async () => {
            // to avoid nextJS build error
            if (typeof window !== 'undefined' && (window as any).ethereum) {
                
                const storedWalletConnectStatus = localStorage.getItem('walletconnect');
                if (storedWalletConnectStatus && storedWalletConnectStatus === 'false') {
                    setIsChecked(true);
                    return;
                }
                
                // eslint-disable-next-line @typescript-eslint/no-explicit-any
                const provider = new ethers.providers.Web3Provider((window as any).ethereum);
                const addresses = await provider.listAccounts();

                if (provider && addresses.length !== 0) {
                    setProvider(provider);
                    setSelectedAddress(addresses[0]);
                    setIsChecked(true);
                } else {
                    setIsChecked(true);
                }
            }
        };

        if (!isChecked) {
            checkProvider();
        }
    }, [isChecked, web3Modal, setProvider, setSelectedAddress]);

    useEffect(() => {
        if (web3Modal) return;
        // requires window to set the modal
        const newWeb3Modal = new Web3Modal({
            network: 'mainnet',
            providerOptions,
            theme: 'dark',
        });
        setWeb3Modal(newWeb3Modal);
    }, [web3Modal]);

    useEffect(() => {
        if (typeof window !== 'undefined' && (window as any).ethereum) {
            if (!provider || !selectedAddress) {
                const defaultProvider_ = new ethers.providers.AlchemyWebSocketProvider('mainnet', process.env.ALCHEMY_PROVIDER_API_KEY);
                
                setDefaultProvider(defaultProvider_);
            }
        }
    }, [provider, selectedAddress]);

    return (
        <BlockchainContext.Provider
            value={{
                provider,
                defaultProvider,
                setProvider,
                setDefaultProvider,
                selectedAddress,
                setSelectedAddress,
                onWalletDisconnect,
                onWalletConnect,
            }}
        >
            {children}
        </BlockchainContext.Provider>
    );
};

export default BlockchainContext;

export { BlockchainProvider };
